<template>
  <b-container class="mt-4">
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label v-text="'Sporcu Raporları'" class="mr-2 text-white"></label>
        <b-button variant="primary" class="ml-2" to="/reports">Geri</b-button>
      </template>
      <InputTile label="Bölge" :custom="true">
        <b-form-select
          v-model="form.region"
          :options="formOptions.cities"
          required
        >
        </b-form-select>
      </InputTile>
      <InputTile label="Cinsiyet" :custom="true">
        <b-form-select
          v-model="form.gender"
          :options="formOptions.gender"
          required
        >
        </b-form-select>
      </InputTile>
      <InputTile label="Kulüp" :custom="true">
        <b-form-select
          v-model="form.club"
          :options="clubs"
          text-field="name"
          value-field="id"
          required
        >
        </b-form-select>
      </InputTile>
      <InputTile label="Lisans" :custom="true">
        <b-form-select
          v-model="form.licence"
          :options="formOptions.licence"
          required
        >
        </b-form-select>
      </InputTile>
      <download-excel
      class="btn btn-success float-right mt-4 ml-2"
        :fetch="getData"
        :fields="
          reports.sport.fields.reduce((obj, e) => {
            obj[e] = e;
            return obj;
          }, {})
        "
        :worksheet="reports.sport.title"
        name="filename.xls"
      >
        Excel al
      </download-excel>
      <b-btn class="float-right mt-4" variant="primary" @click="downloadPdf"
        >Rapor Al</b-btn
      >
    
    </b-card>
  </b-container>
</template>

<script>
import reportHelper from "@/store/helpers/PMSReportHelper";
import reports from "@/store/constants/reports.js";
import InputTile from "../../components/forms/common/InputTile.vue";
import cities from "../../store/constants/cities.js";
import JsonExcel from "vue-json-excel";
import { mapGetters } from "vuex";
export default {
  components: {
    InputTile,
    "download-excel":JsonExcel
    
  },
  computed: {
    ...mapGetters(["getClubNames"]),
    clubs: {
      get() {
        return [{ name: "Tümü", id: 0 }].concat(this.getClubNames);
      },
    },
  },
  data() {
    return {
      reports,
      formOptions: {
        cities: cities.map(function(e) { return e.value == "" ? {text:"Tümü",value:""}: e }), 
        gender: [
          { value: "", text: "Tümü" },
          { value: "male", text: "Erkek" },
          { value: "female", text: "Kadın" },
        ],
        licence: [
          { value: "", text: "Tümü" },
          { value: "licensePending", text: "Lisansı Yenileyenler" },
          { value: "licenseEnded", text: "Lisansı Dolanlar" },
        ],
      },
      form: {
        region: "",
        gender: "",
        licence: "",
        club: 0,
      },
    };
  },
  created() {
    this.$store.dispatch("getClubNames");
  },
  methods: {
    async downloadPdf() {
      reportHelper.generateHTML(
        reports.sport.title,
        reports.sport.fields,
        await reportHelper.getPdfItemsFromServer(
          reports.sport.endpoint,
          this.form
        )
      );
    },
    async getData() {
      const data = await reportHelper.getPdfItemsFromServer(
        reports.sport.endpoint,
        this.form
      );
      return data.map((e) => {
        const row = {};
        reports.sport.fields.forEach((element, index) => {
          row[element] = e[index];
        });
        return row;
      });
    },
  },
};
</script>

<style>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>